exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-example-form-tsx": () => import("./../../../src/pages/example/form.tsx" /* webpackChunkName: "component---src-pages-example-form-tsx" */),
  "component---src-pages-example-request-tsx": () => import("./../../../src/pages/example/request.tsx" /* webpackChunkName: "component---src-pages-example-request-tsx" */),
  "component---src-pages-example-reservation-tsx": () => import("./../../../src/pages/example/reservation.tsx" /* webpackChunkName: "component---src-pages-example-reservation-tsx" */),
  "component---src-pages-example-shop-tsx": () => import("./../../../src/pages/example/shop.tsx" /* webpackChunkName: "component---src-pages-example-shop-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-news-20230510-index-tsx": () => import("./../../../src/pages/news/20230510/index.tsx" /* webpackChunkName: "component---src-pages-news-20230510-index-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

